import React from 'react';
import '../../App.css';
function Servicios() {
    return (
        <div>
            <h1 className="services">Services</h1>
        </div>
    )
}

export default Servicios
