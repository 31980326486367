import React from 'react';
import '../../App.css';
function Productos() {
    return (
        <div>
            <h1 className="products">Productos</h1>
        </div>
    )
}

export default Productos
